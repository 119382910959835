export const TOURNAMENTS_TYPES = {
  new: 'new',
  finished: 'finished',
};
export const TABS_DETAILS = {
  table: 'tournament_table',
  rules: 'tournament_rules',
};
export const TABLES_ITEMS = {
  gameStandings: {
    headers: [
      {
        dictionaryKey: 'general_place',
        fieldName: 'place',
      },
      {
        dictionaryKey: 'general_player',
        fieldName: 'playerName',
      },
      {
        dictionaryKey: 'general_points',
        fieldName: 'score',
      },
      {
        dictionaryKey: 'general_total_prize',
        fieldName: 'prize',
      },
    ],
  },
};